// 
// nestable-list.scss
//

.dd-list {
  .dd-item {
      .dd-handle {
          background: lighten($gray-300, 4%);
          border: none;
          padding: 8px 16px;
          height: auto;
          font-weight: normal;
          border-radius: 3px;
          color: $body-color;
          &:hover {
              color: $primary;
          }
      }
      button {
          height: 36px;
          font-size: 17px;
          margin: 0;
          color: $gray-600;
          width: 36px;
      }
  }
  .dd3-item {
      margin: 5px 0;

      .dd-item button {
          width: 36px;
          height: 36px;
      }
  }
  .dd3-handle {
      margin: 0;
      height: 36px !important;
      float: left;
  }
  .dd3-content {
      height: auto;
      border: none;
      padding: 8px 16px 8px 46px;
      background: lighten($gray-300, 4%);
      &:hover {
          color: $primary;
      }
  }
  .dd3-handle:before {
      content: "\F35C";
      font-family: "Material Design Icons";
      color: $gray-500;
  }
}

.dd-empty,
.dd-placeholder {
  background: rgba($primary,0.07);
  border-color: $gray-400;
}

.custom-dd-empty {
  .dd-list {
    .dd3-handle {
      border: none;
    }
  }
}

.dd-dragel {
  .dd-handle {
      box-shadow: $box-shadow;
  }
  .dd3-handle {
      border: none !important;
  }
}