// 
// components-demo.scss
//

// Demo Only
.button-list {
    margin-left: -8px;
    margin-bottom: -12px;
    
    .btn {
        margin-bottom: 12px;
        margin-left: 8px;
    }
}

/* Dropcap */
.dropcap {
    font-size: 3.1em;
  }
    
.dropcap, .dropcap-circle, .dropcap-square {
    display: block;
    float: left;
    line-height: 46px;
    margin-right: 6px;
    text-shadow: none;
}


// Grid

.grid-structure {
    .grid-container {
        background-color: lighten($gray-200, 2%);
        margin-top: 10px;
        font-size: .8rem;
        font-weight: $font-weight-medium;
        padding: 10px 20px;
    }
}

.switchery-demo {
    .switchery {
        margin-bottom: 10px;
        margin-right: 5px;
    }
}


// Icon demo ( Demo only )
.icons-list-demo {
    div {
        cursor: pointer;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        p {
            margin-bottom: 0;
            line-height: inherit;
        }
    }
    i {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        font-size: 22px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 12px;
    }
    .col-lg-4 {
        background-clip: padding-box;
        margin-top: 10px;
        border-radius: 3px;
        &:hover {
            background-color: rgba($primary, 0.07);
        }
    }
}


// social icon
.social-links{
    li {
      a {
        border-radius: 50%;
        background-color: $gray-300;
        color: $gray-600;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        text-align: center;
        width: 30px;
        &:hover{
            background-color: $gray-300;
            color: $gray-600;
        }
      }
    }
}


// Demos button
.demos-show-btn {
    position: fixed;
    top: 50%;
    right: 0;
    writing-mode: vertical-rl;
    font-weight: 600;
    background-color: $danger;
    color: $white !important;
    line-height: 36px;
    padding: 15px 3px;
    border-radius: 6px 0 0 6px;
    transform: translateY(-50%);
    text-transform: uppercase;
}

@media (max-width: 600px) {
    .demos-show-btn {
        display: none;
    }
}