
// 
// authentication.scss
//

.authentication-page{
    padding-bottom: 0px;
    &.enlarged {
        min-height: 100px;
    }

    h4{
        z-index: 999;
        position: relative;
        font-size: 24px;;
    }
}

.bg-img {
    background: url(../images/small/bg.jpg) center;
}

.bg-overlay {
    background-color: rgba(49, 126, 235, 0.4);
    border-radius: 6px 6px 0px 0px;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
  }

  .user-thumb {
    margin-top: -90px;
    position: relative;
    z-index: 999;
  }
