//
// layouts.scss
//

body {
    &.boxed-layout {
        background-color: $boxed-body-bg;
        #wrapper {
            background-color: $body-bg;
            max-width: $boxed-layout-width;
            margin: 0 auto;
            box-shadow: $box-shadow;
        }

        .navbar-custom {
            max-width: $boxed-layout-width;
            margin: 0 auto;
        }

        .footer {
            margin: 0 auto;
            max-width: calc(#{$boxed-layout-width} - #{$leftbar-width});
        }

        &.enlarged {
            .footer {
                max-width: calc(#{$boxed-layout-width} - #{$leftbar-width-collapsed});
            }
        }
    }
}

@media (min-width: 992px) {
    // Unsticky Layout
    .unsticky-layout {
        .left-side-menu, .navbar-custom {
            position: absolute;
        }

        .left-side-menu{
            .slimscroll-menu{
                height: auto !important;
            }
            .slimScrollBar{
                display: none !important;
            }
        }
    }
}


@media (min-width: 992px) {
    // Unsticky header
    .unsticky-header {
        #topnav, .navbar-custom {
            position: absolute;
        }
    }
}