// 
// profile.scss
//

.bg-img {
    background: url("../images/small/bg.jpg") center;
  }
  
  .bg-overlay {
    background-color: rgba(49, 126, 235, 0.4);
    border-radius: 6px 6px 0px 0px;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
  }
  
  .bg-picture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0px -27px 0 -27px;
    padding: 100px 0px;
    position: relative;
  }
  
  .bg-picture > .bg-picture-overlay {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.4) 100%);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .profile-info-name {
    position: relative;
    z-index: 99;
  }

  .user-tabs {
    background-color: $card-bg;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    line-height: 30px;;
  }